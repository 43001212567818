<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";

export default {
    components:{
        Layout,
        PageHeader,
    },
    data() {
        return {
            // Configuration du tableau
            fields: [
            { key: "typeConducteur", label: "Type Conducteur", sortable: true },
            { key: "semaine", label: "Semaine", sortable: true },
            { key: "jour", label: "Jour", sortable: true },
            { key: "niveauObjectif", label: "Niveau Objectif", sortable: true },
            { key: "coursesMinimales", label: "Courses Min.", sortable: true },
            { key: "montantBonus", label: "Montant Bonus", sortable: true },
            { key: "action", label: "Actions" }
            ],

            title: "GESTION DES BONUS",
            items: [
                {
                text: "Dashboard",
                },
                {
                text: "Bonus",
                active: true,
                },
            ],

            // Données
            bonusList: [],
            filteredBonusList: [], // Nouvelle propriété pour stocker la liste filtrée
            statisticsDay: {},

            // Filtres
            filters: {
                semaine: null,
                jour: null,
                typeConducteur: null
            },

            // Modal form data
            formData: {
                semaine: 1,
                jour: '',
                typeConducteur: '',
                niveauObjectif: 1,
                coursesMinimales: 0,
                montantBonus: 0
            },

            // Options pour les selects
            semaines: [1, 2, 3, 4],
            jours: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'],
            typesConduteur: ['ZEM', 'TRICYCLE', 'BASIC', 'CONFORT', 'VIP', 'ECO'],
            
            // Contrôle des modals
            showAddModal: false,
            showEditModal: false,
            showDeleteModal: false,
            selectedBonusId: null
        };
    },
    methods: {
        // Récupérer la liste des bonus
        async getBonusList() {
            try {
                const response = await apiRequest('GET', 'bonus-list');
                if (response.status === 200) {
                    this.bonusList = response.data;
                    this.applyFilters(); // Applique les filtres après chaque chargement
                }
            } catch (error) {
                this.$toast.error("Erreur lors de la récupération des bonus");
            }
        },

        // Nouvelle méthode pour appliquer les filtres localement
        applyFilters() {
            let result = [...this.bonusList];

            if (this.filters.semaine) {
                result = result.filter(bonus => bonus.semaine === this.filters.semaine);
            }
            if (this.filters.jour) {
                result = result.filter(bonus => bonus.jour === this.filters.jour);
            }
            if (this.filters.typeConducteur) {
                result = result.filter(bonus => bonus.typeConducteur === this.filters.typeConducteur);
            }

            this.filteredBonusList = result;
        },

        // Réinitialiser les filtres
        resetFilters() {
            this.filters = {
                semaine: null,
                jour: null,
                typeConducteur: null
            };
            this.applyFilters();
        },

        // Ajouter un nouveau bonus
        async addBonus() {
            console.log(this.formData);
        try {
        const response = await apiRequest('POST', 'bonus-add', this.formData);
        console.log(response);
        // Si la requête réussit
        if (response.status === 200) {
            this.$toast.success("Bonus ajouté avec succès");
            this.showAddModal = false;
            console.log("bbb");
            await this.getBonusList();
            // Réinitialiser le formulaire
            this.formData = {
            semaine: 1,
            jour: '',
            typeConducteur: '',
            niveauObjectif: 1,
            coursesMinimales: 0,
            montantBonus: 0
            };
        }
        } catch (error) {
            console.log("babab", error.status);
            // Gestion améliorée des erreurs
            this.$toast.error(error.message);
        
        // Si c'est une erreur de conflit (409), fermer le modal
        if (error.status === 409) {
        this.showAddModal = false;
        }
        }
    },

        // Mettre à jour un bonus
        async updateBonus() {
            try {
                const payload = {
                    bonusId: this.selectedBonusId,
                    ...this.formData
                };
                console.log(payload);
                const response = await apiRequest('PUT', 'bonus-update', payload);
                if (response.status === 200) {
                    this.$toast.success("Bonus mis à jour avec succès");
                    this.showEditModal = false;
                    await this.getBonusList();
                }
            } catch (error) {
                this.$toast.error("Erreur lors de la mise à jour");
            }
        },

        // Supprimer un bonus
        async deleteBonus() {
            try {
                const response = await apiRequest('DELETE', 'bonus-delete', {
                    id: this.selectedBonusId
                });
                if (response.status === 200) {
                    this.$toast.success("Bonus supprimé avec succès");
                    this.showDeleteModal = false;
                    await this.getBonusList();
                }
            } catch (error) {
                this.$toast.error("Erreur lors de la suppression");
            }
        },

        // Obtenir les statistiques du jour
        async getDayStatistics() {
            try {
                const response = await apiRequest('GET', 'statistics-day/courses');
                if (response.status === 200) {
                    this.statisticsDay = response.data;
                }
            } catch (error) {
                this.$toast.error("Erreur lors de la récupération des statistiques");
            }
        },

        // Handlers pour les modals
        handleEdit(item) {
            this.selectedBonusId = item.id;
            this.formData = { ...item };
            this.showEditModal = true;
        },

        handleDelete(id) {
            this.selectedBonusId = id;
            this.showDeleteModal = true;
        }
    },

    mounted() {
        this.getBonusList();
        //this.getDayStatistics();
    },

    watch: {
        // Surveiller les changements dans les filtres
        'filters': {
            deep: true,
            handler() {
                this.applyFilters();
            }
        }
    }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbItems" />
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Filtres -->
            <div class="row mb-3">
              <div class="col-md-3">
                <label>Semaine</label>
                <select v-model="filters.semaine" class="form-control">
                  <option :value="null">Toutes les semaines</option>
                  <option v-for="sem in semaines" :key="sem" :value="sem">
                    Semaine {{ sem }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Jour</label>
                <select v-model="filters.jour" class="form-control">
                  <option :value="null">Tous les jours</option>
                  <option v-for="jour in jours" :key="jour" :value="jour">
                    {{ jour }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Type de conducteur</label>
                <select v-model="filters.typeConducteur" class="form-control">
                  <option :value="null">Tous les types</option>
                  <option v-for="type in typesConduteur" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="invisible">Actions</label>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary flex-grow-0 " @click="resetFilters">
                    Réinitialiser
                  </button>
                  <button class="btn btn-primary flex-grow-0" @click="showAddModal = true">
                    <i class="fas fa-plus me-1"></i>
                    Ajouter
                  </button>
                </div>
              </div>
            </div>

            <!-- Tableau des bonus -->
            <b-table 
              :items="filteredBonusList"
              :fields="fields"
              striped
              responsive
            >
              <template #cell(action)="row">
                <div class="d-flex">
                  <button class="btn btn-sm btn-info mr-2" @click="handleEdit(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="btn btn-sm btn-danger" @click="handleDelete(row.item.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Ajout/Edition -->
    <b-modal 
      :visible="showAddModal || showEditModal"
      :title="showAddModal ? 'Ajouter un bonus' : 'Modifier le bonus'"
      @hide="showAddModal = showEditModal = false"
    >
      <form @submit.prevent="showAddModal ? addBonus() : updateBonus()">
        <div class="form-group">
          <label>Semaine</label>
          <select v-model="formData.semaine" class="form-control" required>
            <option v-for="sem in semaines" :key="sem" :value="sem">
              Semaine {{ sem }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Jour</label>
          <select v-model="formData.jour" class="form-control" required>
            <option v-for="jour in jours" :key="jour" :value="jour">
              {{ jour }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Type de conducteur</label>
          <select v-model="formData.typeConducteur" class="form-control" required>
            <option v-for="type in typesConduteur" :key="type" :value="type">
              {{ type }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Niveau Objectif</label>
          <input 
            type="number" 
            v-model="formData.niveauObjectif" 
            class="form-control"
            min="1"
            required
          >
        </div>

        <div class="form-group">
          <label>Courses Minimales</label>
          <input 
            type="number" 
            v-model="formData.coursesMinimales" 
            class="form-control"
            required
          >
        </div>

        <div class="form-group">
          <label>Montant du Bonus</label>
          <input 
            type="number" 
            v-model="formData.montantBonus" 
            class="form-control"
            required
          >
        </div>
      </form>
      
      <template #modal-footer>
        <b-button 
          variant="secondary" 
          @click="showAddModal = showEditModal = false"
        >
          Annuler
        </b-button>
        <b-button 
          variant="primary" 
          @click="showAddModal ? addBonus() : updateBonus()"
        >
          {{ showAddModal ? 'Ajouter' : 'Modifier' }}
        </b-button>
      </template>
    </b-modal>

    <!-- Modal de confirmation de suppression -->
    <b-modal
      v-model="showDeleteModal"
      title="Confirmation de suppression"
      @hide="showDeleteModal = false"
    >
      <p>Êtes-vous sûr de vouloir supprimer ce bonus ?</p>
      
      <template #modal-footer>
        <b-button 
          variant="secondary" 
          @click="showDeleteModal = false"
        >
          Annuler
        </b-button>
        <b-button 
          variant="danger" 
          @click="deleteBonus"
        >
          Supprimer
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;

}
</style>